<template>
  <div v-if="!quizStarted" class="start-screen">
    <h1 class="quiz-title">Segel Quiz - Übe für die BFA-Binnen Prüfung</h1>
    <h1 class="quiz-subtitle">Falls ihr in den Fragen Unstimmigkeiten oder Fehler entdeckt, nutzt bitte den 'Fehler melden' Knopf! <br> Viel Spaß beim üben</h1>
    <div class="chapter-selector">
      <label class="chapter-select-label">Wähle ein Kapitel:</label>
      
      <div class="radio-selection">
      <label class="custom-radio-label" v-for="(stats, chapter) in chaptersWithQuestionCount" :key="chapter">          
        <input type="radio" :value="chapter" v-model="selectedChapter">
        <!-- Check if chapter is 'Random40' and display '40 Zufallsfragen' -->
        {{ chapter === 'Random40' ? '40 Zufallsfragen' : 'Kapitel ' + chapter }} ({{ stats.questionCount }} Fragen)
        <span class="circle-background" :style="{ backgroundColor: getCircleColor(calculateCorrectPercentage(chapter)) }">
          {{ percentageToText(calculateCorrectPercentage(chapter)) }}
        </span>
      </label>                
    </div>

      <div class="randomize-checkbox-container">
        <input type="checkbox" id="randomizeCheckbox" v-model="shouldRandomize" class="styled-checkbox">
        <label for="randomizeCheckbox">Kapitelfragen zufällig mischen</label>
      </div>

      <button class="start-quiz-button" @click="startQuiz">Quiz starten</button>


      <div class="changelog">
      <h2>Updates:</h2>
      <ul>
        <li><span style="color: gray;">6.12.23 / 21:00 - Neu: 40 Zufällige Fragen - Kapitelfragen zufällig</span></li>
        <li><span style="color: gray;">5.12.23 / 13:45 - I7 korrigiert auf C+D.</span></li>
      </ul>
    </div>
    </div>

    
  </div>  

  <div v-if="quizStarted && !showResults" class="quiz-container">
    <h2 class="question">{{ currentQuestion.chapter + currentQuestion.number + ': ' + currentQuestion.question }}</h2>
    <!-- Bild-Element einfügen, wenn das image-Flag gesetzt ist -->
    <img class="question-image" v-if="currentQuestion.image" 
      :src="getImageUrl(currentQuestion.chapter, currentQuestion.number)" alt="Question Image">

    <div v-for="(answer, index) in currentQuestion.answers" :key="index" class="answer-container">
      <input type="checkbox" :disabled="isAnswerChecked || isTimerRunning" :id="'answer-' + index"
        v-model="selectedOptions[index]" class="custom-checkbox">
      <label :class="{
        'correct': isAnswerChecked && currentQuestion.correct[index] === 1,
        'wrong': isAnswerChecked && currentQuestion.correct[index] !== 1 && isAnswerWrong
      }" class="custom-label" :for="'answer-' + index">{{ answer }}</label>

    </div>
    <button class="continue-button" @click="checkAnswersAndContinue"
      :disabled="!isAnyCheckboxChecked || isTimerRunning">Continue</button>
    <div class="question-counter">
      Frage {{ currentQuestionIndex + 1 }} von {{ questions.length }}
    </div>
      <!-- Fehler melden Link -->
    <div class="report-error">
      <a href="#" @click="reportError">Fehler melden</a>
    </div>

  </div>
  <div v-if="showResults" class="results-container">
    <h2>Du hast {{ correctAnswersCount }} von {{ questions.length }} Fragen richtig. Das sind {{ scorePercentage }}%</h2>
    <button class="back-button" @click="resetQuiz">Zurück</button>    
  </div>


</template>


<script>
import questionsData from './questions.json';

export default {
  data() {
    return {
      questions: [],
      currentQuestionIndex: 0,
      selectedOptions: [],
      correctAnswersCount: 0,
      isAnswerChecked: false,
      isTimerRunning: false,
      showResults: false,
      quizStarted: false,
      selectedChapter: '',
      availableChapters: [],
      chaptersWithQuestionCount: {},
      isAnswerWrong: false,
      shouldRandomize: false
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    isAnyCheckboxChecked() {
      return this.selectedOptions.some(option => option);
    },
    scorePercentage() {
      return Math.round((this.correctAnswersCount / this.questions.length) * 100);
    }
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
  },
    startQuiz() {
      this.quizStarted = true;
      this.prepareQuiz();
    },
    getImageUrl(chapter, number) {
      return `/qimg/${chapter}${number}.jpg`.toLowerCase();
    },
    reportError() {
      const username = "david.dengg"; // Dein E-Mail-Benutzername
      const domain = "gmail.com"; // Deine E-Mail-Domain
      window.location.href = "mailto:" + username + "@" + domain;
    },
    resetQuiz() {
      window.location.reload();
    },
    getCircleColor(percentage) {
      percentage = percentage.replace('%', '');
      console.log('percentage', percentage);
      if (percentage == '100') {
        return '#FEE5BA';
      } else if (percentage >= 80) {
        return '#5FE8C2BB';
      } else if (percentage >= 50) {
        return '#FDB232BB';
      } else if (percentage == 0) {
        return '#FFFFFF00';
      } else {
        return '#FF4777BB'; 
      }
    },
    percentageToText(percentage) {
      if (percentage === '100%') {
        return '👑';
      }
      // Check if the percentage string already includes '%'
      if (!percentage.includes('%')) {
        percentage += '%';
      }
      return percentage;
    },
    calculateCorrectPercentage(chapter) {
      const chapterData = this.chaptersWithQuestionCount[chapter];
      if (!chapterData || chapterData.questionCount === 0) {
        // Returning '0%' for chapters with no questions or for Random40
        return '0%';
      }

      const correctAnswers = chapterData.correctCount || 0;
      const percentage = (correctAnswers / chapterData.questionCount) * 100;

      return `${percentage.toFixed(0)}%`;
    },
    prepareQuiz() {
      if (this.selectedChapter === 'Random40') {
        // Randomly pick 40 questions for the 'Random40' chapter
        let shuffledQuestions = [...questionsData];
        this.shuffleArray(shuffledQuestions);
        this.questions = shuffledQuestions.slice(0, 40);
      } else if (this.selectedChapter === '') {
        this.questions = questionsData;
      } else {
        this.questions = questionsData.filter(q => q.chapter === this.selectedChapter);
        if (this.shouldRandomize) {
          this.shuffleArray(this.questions);
        }
      }
      this.currentQuestionIndex = 0;
      this.correctAnswersCount = 0;
      this.selectedOptions = new Array(this.questions.length ? this.questions[0].answers.length : 0).fill(false);
      this.showResults = false;
    },
    checkAnswersAndContinue() {
      this.isAnswerChecked = true;

      // Prüfen, ob die ausgewählten Antworten korrekt sind
      const currentQuestionCorrectAnswers = this.currentQuestion.correct.map(c => c === 1);
      const isCurrentAnswerCorrect = this.selectedOptions.every((option, index) =>
        option === currentQuestionCorrectAnswers[index]
      );

      if (isCurrentAnswerCorrect) {
        this.correctAnswersCount++;
      }

      // Timer-Dauer festlegen
      const timerDuration = !isCurrentAnswerCorrect ? 4500 : 1500;

      this.isTimerRunning = true;
      setTimeout(() => {
        if (this.currentQuestionIndex < this.questions.length - 1) {
          // Gehe zur nächsten Frage
          this.moveToNextQuestion();
        } else {
          // Alle Fragen beantwortet, zeige Ergebnisse an
          this.showResults = true;
          this.setCookieForChapter(this.selectedChapter, this.correctAnswersCount);
        }
      }, timerDuration);
    },
    moveToNextQuestion() {
      this.currentQuestionIndex++;
      this.selectedOptions = new Array(this.currentQuestion.answers.length).fill(false);
      this.isAnswerChecked = false;
      this.isTimerRunning = false;
    },
    setCookieForChapter(chapter, correctCount) {
      let quizStats = {};

      if (document.cookie.includes('quizStats')) {
        quizStats = JSON.parse(document.cookie.split('quizStats=')[1].split(';')[0]);
      }

      quizStats[chapter] = correctCount;


      const expires = new Date();
      expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000)); // 1 Jahr Gültigkeit
      document.cookie = `quizStats=${JSON.stringify(quizStats)};expires=${expires.toUTCString()};path=/`;
    }
  },
  mounted() {
    this.availableChapters = [...new Set(questionsData.map(q => q.chapter))];

    // Adding 'Random40' as a special chapter
    this.availableChapters.push('Random40');

    // Initialize chaptersWithQuestionCount for each chapter including Random40
    this.availableChapters.forEach(chapter => {
      if (chapter === 'Random40') {
        this.chaptersWithQuestionCount[chapter] = { questionCount: 40, correctCount: 0 };
      } else {
        const questionCount = questionsData.filter(q => q.chapter === chapter).length;
        this.chaptersWithQuestionCount[chapter] = { questionCount, correctCount: 0 };
      }
  });

    // Lade die Kapitelstatistiken aus den Cookies und logge sie
    if (document.cookie.includes('quizStats')) {
      const quizStats = JSON.parse(document.cookie.split('quizStats=')[1].split(';')[0]);

      Object.keys(quizStats).forEach(chapter => {
        if (this.chaptersWithQuestionCount[chapter]) {
          this.chaptersWithQuestionCount[chapter].correctCount = quizStats[chapter];
        }
      });
    }
  }

};
</script>


<style scoped>
.start-screen {
  text-align: center;
  padding-top: 50px;
}

.chapter-selector {
  margin: auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}


.chapter-select-label {
  margin-bottom: 10px;
  font-size: 18px;
}

.start-quiz-button {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.question-image {
    max-width: 100%; /* Ensures the image width is no more than 100% of its container */
    max-height: 500px; /* Maximum height */
    width: auto;  /* Scales the width automatically to maintain the aspect ratio */
    height: auto; /* Scales the height automatically, but up to 500px */
    display: block;
    margin: 0 auto;
}

.answer-container {
  display: flex;
  align-items: center;
  /* Aligns checkbox and label vertically */
  margin-bottom: 15px;
  /* Adjust this value to increase distance between each pair */
  margin-left: 30px;
}

.quiz-container {
  text-align: left;
}

.results-container {
  text-align: center;
}

.question {
  /* Stil für die Frage */
  margin-left: 20px;
  /* Optional: Abstand von der linken Seite */
  font-size: 18px;
  /* Larger font size for the button */
}

.continue-button {
  font-size: 18px;
  /* Larger font size for the button */
  padding: 10px 20px;
  /* Top & bottom padding, Left & right padding */
  background-color: #007bff;
  /* Blue background color */
  color: white;
  /* White text color */
  border: none;
  /* Removes the border */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Hand cursor on hover */
  margin-top: 20px;
  /* More space above the button */
  margin-left: 20px;
  /* Space between checkbox and label */
}

.continue-button:disabled {
  background-color: #cccccc;
  /* Gray background for disabled state */
  color: #666666;
  /* Darker text color for disabled state */
  cursor: not-allowed;
  /* Cursor to indicate the button is not clickable */
}


.custom-checkbox {
  width: 25px;
  /* Size of the checkbox */
  height: 25px;
  /* Size of the checkbox */
  cursor: pointer;
  /* Changes cursor to pointer on hover */
  vertical-align: middle;
  /* Aligns checkbox vertically */
}

.custom-label {
  margin-left: 10px;
  /* Space between checkbox and label */
  font-size: 17px;
  /* Larger font size for readability */
  cursor: pointer;
  /* Changes cursor to pointer on hover */
  vertical-align: middle;
  /* Aligns label text vertically */
}

.correct {
  color: green;
  font-weight: bold;
}

.wrong {
  color: red;
  font-weight: bold;
}

.question-counter {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 18px;
}


.radio-selection {
  text-align: left;
  /* Links ausrichten der Radio-Buttons */
  padding: 10px;
  /* Optionaler Abstand */
  border: 1px solid #cccccc;
  /* Optionaler Rahmen */
  border-radius: 5px;
  /* Abgerundete Ecken */
  margin-bottom: 20px;
  /* Abstand nach unten */
  width: 100%;
  /* Ganze Breite nutzen */
  box-sizing: border-box;
  text-align: left;
  /* Box-Modell für Breitenberechnung */
}

.custom-radio-label {
  display: flex;
  min-width: 300px;
  align-items: center; /* Vertikal zentrieren */
  justify-content: flex-start; /* Elemente links ausrichten */
  margin: 10px 0;
  font-size: 20px;
}

.custom-radio-label input[type="radio"] {
  transform: scale(1.5);
  /* Skaliert die Radio-Buttons um das 1,5-Fache ihrer normalen Größe */
  margin-right: 15px;
  /* Anpassen des Abstands zwischen Radio-Button und Text */
  vertical-align: middle;
  /* Stellt sicher, dass der Radio-Button vertikal zentriert ist */
}

.circle-background {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-left: auto; /* Fügt automatischen Abstand links hinzu, um nach rechts zu rücken */
}

.back-button {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.report-error {
  margin-top: 10px;
  text-align: left;
  margin-left: 20px;
}

.report-error a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.quiz-title {
  text-align: center;
  font-size: 28px; /* Ändere die Schriftgröße nach Bedarf */
  margin-top: 10px; /* Abstand nach oben, anpassbar */
  margin-bottom: 20px; /* Abstand nach unten, anpassbar */
}

.quiz-subtitle {
  text-align: center;
  font-size: 20px; /* Ändere die Schriftgröße nach Bedarf */
  margin-top: 10px; /* Abstand nach oben, anpassbar */
  margin-bottom: 20px; /* Abstand nach unten, anpassbar */
}

.changelog {
    font-size: 12px;
    color: black;
    padding: 15px;
    border-radius: 5px;
    margin-top: 30px;
    text-align: left;
  }

.randomize-checkbox-container {
  display: flex;
  align-items: center; /* Aligns checkbox and label vertically */
  margin-bottom: 10px;
}

.styled-checkbox {
  width: 25px;  /* Larger checkbox size */
  height: 25px; /* Larger checkbox size */
  margin-right: 10px; /* Space between checkbox and label */
  cursor: pointer;
  /* For accessibility, you might want to add some focus and hover styles */
}

label[for="randomizeCheckbox"] {
  margin: 0; /* Adjust as needed */
  font-size: 16px; /* Adjust as needed */
  cursor: pointer; /* Changes cursor to pointer on hover */
  /* Additional label styles if needed */
}
</style>
